/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import { Alert } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import { Modal } from '../../../../UI/Modal';
import { Col, FormRow } from '../../../../UI/Grid';
import { ITerminalMapping } from '../../../../types/UKNomination/FieldNomination.type';
import FormControlTextInput from '../../../../UI/FormComponent/FormControlTextInput';
import {
  ADD_UPDATE_TERMINAL_MAPPINGS,
  MESSAGES,
} from '../../../../constants/UKNomination/FieldNomination';
import FormControlSelect from '../../../../UI/FormComponent/FormControlSelect';
import { ModalFooter } from '../style';
import Button from '../../../../UI/Button';
import { post } from '../../../../services/COPApi';
import { useToast } from '../../../../UI/Toast';
import useGetProductionFields from '../../../../hooks/UKOps/useGetProductionFields';
import useGetSubTerminals from '../../../../hooks/UKOps/useGetSubTerminals';
import useGetTerminals from '../../../../hooks/UKOps/useGetTerminals';
import useGetSourceProductionFields from '../../../../hooks/UKOps/useGetSourceProductionFields';
import FormControlSelectUOM from './uom';
import FormControlDatePicker from '../../../../UI/FormComponent/FormControlDatePicker';
import FormControlSelectFormula from './formula';

const FormProviderWrapper = styled.div`
  .invalid {
    border: 1px solid rgba(217, 54, 56, 1);
  }

  .already-exists {
    color: rgba(217, 54, 56, 1);
    font-style: italic;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }

  .shell-date-picker-time-panel-column:last-child {
    display: none;
  }
  .formula {
    font-size: 12px;
    font-weight: 500;
  }
`;

interface IAddEditTerminalMapping {
  open: boolean;
  onClose: () => void;
  selectedItem: ITerminalMapping | null;
  refreshData: () => void;
  checkExisting: (formData: ITerminalMapping) => {
    error: boolean;
    message: string;
  };
  sourceProductionFieldExists: (sourceProductionFieldId: number) => boolean;
}

const AddEditTerminalMapping: React.FC<IAddEditTerminalMapping> = ({
  open = false,
  onClose = () => {},
  selectedItem,
  refreshData = () => {},
  checkExisting,
  sourceProductionFieldExists,
}) => {
  const methods = useForm();
  const {
    reset,
    formState: { isDirty },
    watch,
  } = methods;
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const [alreadyExists, setAlreadyExists] = useState<{
    error: boolean;
    message: string;
  }>({
    error: false,
    message: '',
  });

  const { loadingProductionFields, productionFields } =
    useGetProductionFields(open);
  const { loadingTerminals, terminals } = useGetTerminals();
  const { loadingSubTerminals, subTerminals } = useGetSubTerminals(open);
  const { loadingSourceProductionFields, sourceProductionFields } =
    useGetSourceProductionFields();

  const sourceProductionFieldId = watch('sourceProductionFieldId');
  const timer = watch('timer');
  const daVarianceBreach = watch('daVarianceBreach');
  const wdVarianceBreach = watch('wdVarianceBreach');
  const formulaId = watch('formulaId');

  useEffect(() => {
    if (selectedItem && open) {
      reset({
        zKey: selectedItem.zKey,
        sourceProductionFieldId: selectedItem.sourceProductionFieldId,
        productionFieldId: selectedItem.productionFieldId,
        terminalId: selectedItem.terminalId,
        subTerminalId: selectedItem.subTerminalId,
        formulaId: selectedItem.formulaId,
        daVarianceBreach: selectedItem.daVarianceBreach,
        wdVarianceBreach: selectedItem.wdVarianceBreach,
        unit: selectedItem.unit,
        timer: selectedItem.timer
          ? dayjs(`1/1/1 ${selectedItem.timer}`)
          : undefined,
      });
    }
    if (!selectedItem && open) {
      reset({
        wdVarianceBreach: 10,
        daVarianceBreach: 25,
      });
    }
  }, [selectedItem, open]);

  const handleOnClose = () => {
    setAlreadyExists({
      error: false,
      message: '',
    });
    reset({
      zKey: '',
      unit: null,
      sourceProductionFieldId: null,
      productionFieldId: null,
      terminalId: null,
      subTerminalId: null,
      timer: undefined,
      formulaId: null,
      daVarianceBreach: '',
      wdVarianceBreach: '',
    });
    onClose();
  };

  const handleOnSubmit = (formData) => {
    const { error, message } = checkExisting(formData);
    setAlreadyExists({
      error,
      message,
    });
    if (error) return;
    setLoading(true);
    let payload = formData;
    if (selectedItem) {
      payload = {
        ...payload,
        id: selectedItem?.id,
        daVarianceBreach: formData.daVarianceBreach || undefined,
        wdVarianceBreach: formData.wdVarianceBreach || undefined,
      };
    }
    post(ADD_UPDATE_TERMINAL_MAPPINGS, {
      ...payload,
      daVarianceBreach: formData.daVarianceBreach || null,
      wdVarianceBreach: formData.wdVarianceBreach || null,
      timer: formData.timer ? dayjs(formData.timer).format('HH:mm') : undefined,
    })
      .then(() => {
        toast?.open(
          `Mapping ${selectedItem ? 'Updated' : 'Created'} Successfully!`,
          'success'
        );
        handleOnClose();
        refreshData();
      })
      .catch((response) => {
        if (response.response.status === 400) {
          setAlreadyExists({
            error: true,
            message: response?.response?.data?.message,
          });
          return;
        }
        toast?.open(MESSAGES.SERVER_ERROR, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderFooter = () => (
    <ModalFooter>
      <Button
        onClick={methods.handleSubmit(handleOnSubmit)}
        loading={loading}
        disabled={loading || !isDirty}
      >
        {selectedItem ? 'Update' : 'Save'}
      </Button>
      <Button variant='outlined' onClick={handleOnClose}>
        Cancel
      </Button>
    </ModalFooter>
  );

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
      title={`${selectedItem ? 'Edit' : 'Add New'} Mapping`}
      isDraggable
      maskClosable={false}
      footer={renderFooter()}
      width={570}
    >
      <FormProviderWrapper>
        <FormProvider {...methods}>
          <FormRow>
            <Col size={8}>
              <FormControlTextInput
                controllerProps={{
                  name: 'zKey',
                  rules: {
                    required: MESSAGES.REQUIRED_Z_KEY,
                    pattern: {
                      value: /^\d+$/,
                      message: MESSAGES.INVALID_Z_KEY,
                    },
                  },
                }}
                label='zKey'
                data-testid='zKey'
                methods={methods}
              />
            </Col>
            <Col size={4}>
              <FormControlSelectUOM
                name='unit'
                label='Unit'
                data-testid='unit'
                required={MESSAGES.REQUIRED_UNIT}
                methods={methods}
                placeholder='Unit'
                data={['Therms', 'MWh', 'GWh']}
                isLoading={false}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col size={8}>
              <FormControlSelect
                name='sourceProductionFieldId'
                label='Source Production Field'
                required={MESSAGES.REQUIRED_SOURCE_PRODUCTION_FIELD}
                isLoading={loadingSourceProductionFields}
                data={sourceProductionFields}
                methods={methods}
                data-testid='sourceProductionFieldId'
              />
            </Col>
            <Col size={4}>
              <FormControlDatePicker
                name='timer'
                label='Deadline'
                required={false}
                methods={methods}
                data-testid='timer'
                inputProps={{
                  picker: 'time',
                  placeholder: 'HH:mm',
                  format: 'HH:mm',
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col size={12}>
              <FormControlSelectFormula
                placeholder='Select Formula'
                name='formulaId'
                label='Formula'
                required={false}
                methods={methods}
                data-testid='formula'
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col size={6}>
              <FormControlTextInput
                controllerProps={{
                  name: 'wdVarianceBreach',
                  rules: {
                    min: { value: 0, message: MESSAGES.WD_DA_VARIANCE_RANGE },
                    max: { value: 100, message: MESSAGES.WD_DA_VARIANCE_RANGE },
                  },
                }}
                label='WD Variance Breach (%)'
                data-testid='wdVarianceBreach'
                methods={methods}
                inputProps={{
                  type: 'number',
                }}
              />
            </Col>
            <Col size={6}>
              <FormControlTextInput
                controllerProps={{
                  name: 'daVarianceBreach',
                  rules: {
                    min: { value: 0, message: MESSAGES.WD_DA_VARIANCE_RANGE },
                    max: { value: 100, message: MESSAGES.WD_DA_VARIANCE_RANGE },
                  },
                }}
                label='DA Variance Breach (%)'
                data-testid='daVarianceBreach'
                methods={methods}
                inputProps={{
                  type: 'number',
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col size={12}>
              <FormControlSelect
                name='productionFieldId'
                label='Production Field'
                required={MESSAGES.REQUIRED_PRODUCTION_FIELD}
                isLoading={loadingProductionFields}
                data={productionFields}
                methods={methods}
                data-testid='productionField'
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col size={12}>
              <FormControlSelect
                name='terminalId'
                label='Terminal'
                required={MESSAGES.REQUIRED_TERMINAL}
                isLoading={loadingTerminals}
                data={terminals}
                methods={methods}
                data-testid='terminalId'
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col size={12}>
              <FormControlSelect
                name='subTerminalId'
                label='Sub Terminal'
                required={MESSAGES.REQUIRED_SUB_TERMINAL}
                isLoading={loadingSubTerminals}
                data={subTerminals}
                methods={methods}
                data-testid='subTerminalId'
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col size={12}>
              {alreadyExists.error && (
                <div className='already-exists'>{`Mapping already exists! ${alreadyExists.message}`}</div>
              )}
              {sourceProductionFieldExists(sourceProductionFieldId) &&
              (timer || daVarianceBreach || wdVarianceBreach || formulaId) ? (
                <Alert state='info'>
                  {MESSAGES.MAPPING_OVERRIDE.replace(
                    'addorupdate',
                    selectedItem ? 'updating a' : 'adding a new'
                  )}
                </Alert>
              ) : null}
            </Col>
          </FormRow>
        </FormProvider>
      </FormProviderWrapper>
    </Modal>
  );
};

export default AddEditTerminalMapping;

import React from 'react';
import IAppRoute from './AppRoute.type';
import routePaths from './routepaths';
import StoragePortfolioMainComp from '../pages/StoragePortfolio';

const StoragePortfolioRoutes: IAppRoute[] = [
  {
    path: routePaths.storagePortfolioDashboard,
    element: <StoragePortfolioMainComp type='dashboard' />,
  },
  {
    path: routePaths.dealParameters,
    element: <StoragePortfolioMainComp type='deal-parameters' />,
  },
  {
    path: routePaths.faciltyOverview,
    element: <StoragePortfolioMainComp type='faciltyOverview' />,
  },
  {
    path: routePaths.facilityAttachment,
    element: <StoragePortfolioMainComp type='faciltyAttachment' />,
  },
  {
    path: routePaths.dynamicTable,
    element: <StoragePortfolioMainComp type='dynamicTable' />,
  },
  {
    path: routePaths.facilityCompare,
    element: <StoragePortfolioMainComp type='facilityCompare' />,
  },
];

export default StoragePortfolioRoutes;

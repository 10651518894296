import React from 'react';

import CheckAuthorizationWithSuspense from '../../components/OtherComponents/CheckAuthorizationWithSuspense';
import { applicationName } from '../../constants/Auth';

const DashboardComp = React.lazy(
  () => import('../../components/StoragePortfolio/dashboard')
);

const DealParametersComp = React.lazy(
  () => import('../../components/StoragePortfolio/DealParamaters')
);

const FacilityOverviewComp = React.lazy(
  () => import('../../components/StoragePortfolio/FacilityOverview')
);

const FacilityAttachmentComp = React.lazy(
  () =>
    import('../../components/StoragePortfolio/FacilityOverview/AddAttachment')
);

const DynamicTableComp = React.lazy(
  () => import('../../components/StoragePortfolio/dashboard/DynamicTable')
);

const FacilityCompareComp = React.lazy(
  () =>
    import('../../components/StoragePortfolio/FacilityOverview/facilityCompare')
);

type PSMainProps =
  | 'dashboard'
  | 'deal-parameters'
  | 'faciltyOverview'
  | 'faciltyAttachment'
  | 'dynamicTable'
  | 'facilityCompare';

interface StoragePortfolioMainCompProps {
  type: PSMainProps;
}

const StoragePortfolioMainComp: React.FC<StoragePortfolioMainCompProps> = ({
  type,
}) => {
  return (
    <CheckAuthorizationWithSuspense pageName={applicationName.storagePortfolio}>
      {type === 'dashboard' && <DashboardComp />}
      {type === 'deal-parameters' && <DealParametersComp />}
      {type === 'faciltyOverview' && <FacilityOverviewComp />}
      {type === 'faciltyAttachment' && <FacilityAttachmentComp />}
      {type === 'dynamicTable' && <DynamicTableComp />}
      {type === 'facilityCompare' && <FacilityCompareComp />}
    </CheckAuthorizationWithSuspense>
  );
};

export default StoragePortfolioMainComp;
